/**
 * IMPORTANT NOTE!!
 * alfa.js jeep.js and fiat.js
 * are identical pages with different background images
 *
 * When implementing changes, remember to update all three files
 *
 * OR
 *
 * Refactor to route file structure and pass background image as a prop!
 *
 */

import React, { useState } from 'react';
import '../style/custom.css';
import '../style/reset.css';
import '../style/material-input-fields.css';
import navLogo from '../images/Mobile-Logo.svg';
import coupon from '../images/coupon.png';
import backgroundImage from '../images/alfa.jpg';
import alfaLogo from '../images/alfa-romeo-logo1.png';
import fiatLogo from '../images/fiat-logo1.jpg';
import jeepLogo from '../images/jeep-logo.png';
import signature from '../images/signature.svg';

// styles
const navStyle = {
	top: '0',
	height: '40px',
	backgroundColor: 'black',
	background: 'black',
	display: 'flex',
	alignItems: 'center',
};

const navLogoStyle = {
	height: '20px',
	marginLeft: '1.5em',
};

const backgroundImageStyle = {
	backgroundImage: `url(${backgroundImage})`,
	/* 	minHeight: 'calc(100vh - 40px)',
	 maxHeight: '500px', */
	overflow: 'hidden',
	paddingTop: '1.5em',
	backgroundSize: 'cover',
	backgroundRepeat: 'no-repeat',
	backgroundPosition: '60% center',
	textAlign: 'center',
};

const couponStyle = {
	margin: '0 auto 5% auto',
	display: 'relative',
	left: '90%',
	height: 'auto',
	width: '300px',
	transform: 'rotate(-18deg)',
};

const formStyle = {
	marginTop: '1.1em',
	position: 'relative',
	top: '-2rem',
	display: 'flex',
	flexDirection: 'column',
	width: '75%',
	margin: '0 auto',
	padding: ' 7% 5%',
	color: '#FFF',
	backgroundColor: '#0000006e' /* #1d81956e */,
};

const formTextStyle = {
	color: '#f6f5f4',
	fontSize: '1em',
	marginBlockStart: '4px',
	marginBlockEnd: '12px',
};

const textInputFieldStyle = { height: '20px', margin: '0.2rem 0' };

const materialInputStyle = {
	color: 'white',
};
const checkboxWrapperStyles = {
	margin: '1.4em 0.5em',
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	textAlign: 'start',
	fontSize: '0.7em',
};

const checkboxStyle = {
	transform: 'scale(1.6)',
	backgroundColor: 'red',
};

const errorMessageStyle = {
	backgroundColor: 'rgba(0, 0, 0, 0.9)',
	color: 'red',
	padding: '5px',
	/* 	border: '2px solid red', */
	fontSize: '0.9em',
};

const formButtonStyle = {
	width: '60%',
	fontSize: '1.1em',
	height: '35px',
	padding: '0 7px',
	color: 'white',
	backgroundColor: '#f26322',
	border: 'none',
	borderRadius: '5px',
	margin: '0.8em auto',
};

const footerStyle = {
	height: '30px',
	borderRadius: '7px',
	margin: '2em 3em',
};

const carLogoWrapperStyle = {
	width: '100%',
	height: '90px',
	paddingBottom: '30px',
};
const carLogoStyle = {
	height: '70%',
	width: 'auto',
	objectFit: 'contain',
	marginRight: '1em',
};

const signatureStyle = { textAlign: 'center' };

const signatureSignatureStyle = {
	height: '4rem',
	width: 'auto',
	position: 'relative',
};

const signatureNameWrapper = {
	marginTop: '-15px',
};

const signatureLogoStyle = {
	width: '70%',
	height: 'auto',
	marginBottom: '40px',
};

const IndexPage = () => {
	const [sending, setSending] = useState(false);
	const [error, setError] = useState(null);
	const [success, setSuccess] = useState(false);
	async function submitForm(event) {
		event.preventDefault();
		setError(null);
		// const urlPrefixLocal = 'http://localhost:44367';
		// const urlPrefixLive = 'https://338143-www.aeston.no';

		const urlPath = '/api/form/sendmail';

		const url = urlPath;
		console.log('url', url);

		let payloadObject = {
			regnr: document.getElementById('regnr').value,
			name: document.getElementById('name').value,
			phone: document.getElementById('phone').value,
			email: document.getElementById('email').value,
			mailingList: document.getElementById('mailingList').checked,
		};

		setSending(true);
		await fetch(url, {
			method: 'POST',
			mode: 'cors',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(payloadObject),
		})
			.then((response) => response.json())
			.then((data) => {
				if (data === 'ok') {
					setSuccess(true);
				} else {
					setError('Noe gikk galt. Har du skrevet inn riktig epost-adresse?');
				}
			})
			.catch((error) => {
				setError('Noe gikk galt. Prøv på nytt');
				console.error('error', error);
			});
		setSending(false);
	}

	return (
		<main>
			<title>Mobile Sør - verdikampanje</title>
			<nav style={navStyle}>
				<a href='https://mobile.no/forhandlere/mobile-auto-sor'>
					<img style={navLogoStyle} src={navLogo} alt='the company logo' />
				</a>
			</nav>
			<div style={backgroundImageStyle}>
				<img style={couponStyle} src={coupon} alt='Golden ticket coupon' />
				{success ? (
					<div style={formStyle}>
						<h3>
							Takk for din henvendelse. Du vil bli kontaktet for å bestille time
							via telefon eller e-post.
						</h3>
					</div>
				) : (
					<form
						id='form'
						onSubmit={submitForm}
						style={formStyle}
						action='submit'
					>
						<h2 style={formTextStyle}>
							Fyll inn din
							<br />
							kontaktinformasjon, så vil du <br /> bli oppringt for
							timebestilling.
						</h2>
						<label
							style={materialInputStyle}
							className='pure-material-textfield-outlined'
						>
							<input
								style={textInputFieldStyle}
								disabled={sending}
								type='text'
								placeholder=' '
								name='regnr'
								id='regnr'
								required
							/>
							<span>Registreringsnummer</span>
						</label>
						<label
							style={materialInputStyle}
							className='pure-material-textfield-outlined'
						>
							<input
								style={textInputFieldStyle}
								disabled={sending}
								type='text'
								placeholder=' '
								name='name'
								id='name'
								required
							/>
							<span>Navn</span>
						</label>
						<label
							style={materialInputStyle}
							className='pure-material-textfield-outlined'
						>
							<input
								style={textInputFieldStyle}
								disabled={sending}
								type='tel'
								placeholder=' '
								name='phone'
								id='phone'
								required
							/>
							<span>Telefonnummer</span>
						</label>
						<label
							style={materialInputStyle}
							className='pure-material-textfield-outlined'
						>
							<input
								style={textInputFieldStyle}
								disabled={sending}
								type='mail'
								placeholder=' '
								name='email'
								id='email'
								required
							/>
							<span>E-post</span>
							{error && <p style={errorMessageStyle}>{error}</p>}
						</label>
						<div style={checkboxWrapperStyles}>
							<label htmlFor='newscheckbox'>
								Ønsker du å motta gode tilbud, kampanjer, arrangementer og
								kundekvelder? Kryss av her.
							</label>
							<input
								type='checkbox'
								name='mailingList'
								id='mailingList'
								style={checkboxStyle}
							/>
						</div>
						<button style={formButtonStyle} type='submit'>
							{sending ? 'Sender...' : 'Send'}
						</button>
					</form>
				)}
			</div>
			<div style={footerStyle}>
				<div style={signatureStyle}>
					<p>Vennlig hilsen</p>
					<img
						style={signatureSignatureStyle}
						src={signature}
						alt='the company logo'
					/>
					<div style={signatureNameWrapper}>
						<p>Fredrik Løkke</p>
						<p>468 53 702</p>
						<p>Mobile Auto Sør AS</p>
					</div>
					<a href='https://mobile.no/forhandlere/mobile-auto-sor'>
						<img
							style={signatureLogoStyle}
							src={navLogo}
							alt='the company logo'
						/>
					</a>
					<div style={carLogoWrapperStyle}>
						<a href='https://mobile.no/nybil/personbil/alfa-romeo/'>
							<img style={carLogoStyle} src={alfaLogo} alt='the company logo' />
						</a>
						<a href='https://mobile.no/nybil/personbil/fiat/'>
							<img style={carLogoStyle} src={fiatLogo} alt='the company logo' />
						</a>
						<a href='https://mobile.no/nybil/personbil/jeep/'>
							<img style={carLogoStyle} src={jeepLogo} alt='the company logo' />
						</a>
					</div>
				</div>
			</div>
		</main>
	);
};

export default IndexPage;
